#screen{
    /* border: 1px double cyan; */
    width: 50%;
    top: .1em;
    display: inline-block;
    position: relative;
    margin: 0 auto 5em;
}

/* margin: 25px 50px 75px 100px;
top margin is 25px
right margin is 50px
bottom margin is 75px
left margin is 100p */

#chatcontainer{
    font-family: pixelated;
    display: inline-block;
    width: 70%;
    position: relative;
    margin: 0 auto;
    background-color: #313131;
    border: 2px solid rgba(0, 0, 0, .9);
    box-shadow: 5px 5px rgba(0, 0, 0, .8);
    margin-top: .5rem;
    padding: .1rem;
}

#chatform{
    width: 100%;
    text-align: left;
    position: relative;
}

.chatcomponent{
    margin: .3rem;
}

input[type=text] {
    position: relative;
    width: 60%;
}

#playerinfocontainer{
    font-family: pixelated;
    width: 70%; 
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-color: #313131;
    border: 2px solid rgba(0, 0, 0, .9);
    box-shadow: 5px 5px rgba(0, 0, 0, .8);
}

.skillcontainer{
    display: inline-block;
    position: relative;
    top: -.5rem;
    color: lightgray;
    font-size: 1.5rem;
    z-index: 900;
  }

#logwindow{
    display: inline-block;
    position: relative;
    text-align: left;
    color: lightgray;
    font-family: pixelated;
    width: 50%;
    font-size: 1.15em;
}

#chatmessages{
    color: lightgray;
    font-size: 1.4em;
    position: relative;
    text-align: left;
    padding: .5rem;
    width: 100%;
    height: 4em;
}

#charactercontainer{
    display: inline-block;
    width: 90%;
    position: relative;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, .5);
    box-shadow: 5px 5px rgba(0, 0, 0, .8);
  }
  
.charactercard{
    display: inline-block;
    color: lightgray;
    font-family: pixelated;
    font-size: 1.5em;
    margin: 1rem;
    padding: .3rem;
    border: 2px solid rgba(0, 0, 0, .5);
    box-shadow: 5px 5px rgba(0, 0, 0, .8);
    width: 12rem;
  }
  
#map{
    display: inline-block;
    transform: scale(2,2);
    position: relative;
    width: 13em;
    box-shadow: 3px 3px rgba(0, 0, 0, .8);
}

#game{
    padding-top: 5rem;
    width: 80%;
    margin: 0 auto;
    position: relative;
}

.playercard{
    display: inline-block;
    position: relative;
    width: 30%;
    margin: 1rem;
    padding: .5rem;
}

span.cardnametag{
    position: relative;
    color: lightgray;
    top: -.5rem;
    font-size: 2em;
}

div.log{
    font-size: 1.15em;
}

@font-face {
    font-family: pixelated;
    src: url(./assets/m5x7.ttf);
  }

#loadingscreen{
    padding: .1rem;
    font-family: pixelated;
    width: 70%; 
    font-size: 3em;
    color: whitesmoke;
    margin-top: 7rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

