div.tilerow{
    height: 1em;
    width: 13em;
    border: 0px;
    position: relative;

}

.wall_top_left{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -16px -0px;
    width: 16px;
    height: 16px;
    position: relative;
}

.wall_top_mid{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -32px -0px;
    width: 16px;
    height: 16px;
    position: relative;
}

.wall_top_right{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -48px -0px;
    width: 16px;
    height: 16px;
    position: relative;
}

.wall_left{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -16px -16px;
    width: 16px;
    height: 16px;
    position: relative;
}

.wall_mid{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -32px -16px;
    width: 16px;
    height: 16px;
    position: relative;
}

.wall_right{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -48px -16px;
    width: 16px;
    height: 16px;
    position: relative;
}

.floor_1{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -16px -64px;
    width: 16px;
    height: 16px;
    position: relative;
}
.floor_2{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -32px -64px;
    width: 16px;
    height: 16px;
    position: relative;
}
.floor_3{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -48px -64px;
    width: 16px;
    height: 16px;
    position: relative;
}
.floor_4{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -16px -80px;
    width: 16px;
    height: 16px;
    position: relative;
}
.floor_5{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -32px -80px;
    width: 16px;
    height: 16px;
    position: relative;
}
.floor_6{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -48px -80px;
    width: 16px;
    height: 16px;
    position: relative;
}
.floor_7{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -16px -96px;
    width: 16px;
    height: 16px;
    position: relative;
}
.floor_8{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -32px -96px;
    width: 16px;
    height: 16px;
    position: relative;
}

.wall_side_top_left{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -0px -112px;
}
.wall_side_top_right {
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -16px -112px;
}
.wall_side_mid_left{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -0px -128px;
}
.wall_side_mid_right {
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -16px -128px;
}
.wall_side_front_left{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -0px -144px;
}
.wall_side_front_right {
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -16px -144px;
}

.wall_corner_top_left{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -32px -112px
}
.wall_corner_top_right{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -48px -112px
}
.wall_corner_left{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -32px -128px
}
.wall_corner_right{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -48px -128px
}
.wall_corner_bottom_left{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -32px -144px
}
.wall_corner_bottom_right{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -48px -144px
}
.wall_corner_front_left{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -32px -160px
}
.wall_corner_front_right{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -48px -160px
}

.wall_inner_corner_l_top_left{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -80px -128px
}
.wall_inner_corner_l_top_right{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -64px -128px
}
.wall_inner_corner_mid_left{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -80px -144px
}
.wall_inner_corner_mid_right{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -64px -144px
}
.wall_inner_corner_t_top_left{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -80px -160px
}
.wall_inner_corner_t_top_right{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    width: 16px;
    height: 16px;
    position: relative;
    background-position: -64px -160px
}

.out_of_bounds{
    display: inline-block;
    background-color: black;
    width: 16px;
    height: 16px;
    position: relative;
}

.display-coords{
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    font-size: 8px;
    border: 1px solid red;
}