/* knight animation */

.knight-idle{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -128px -68px;
    width: 16px;
    height: 28px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    animation: knight-idle 0.4s steps(4) infinite;
  }
  
  /* .knight-walk{ */
  .human{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -192px -68px;
    width: 16px;
    height: 28px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    animation: knight-walk 0.4s steps(4) infinite;
  }

  
  
  @keyframes knight-idle {
    from {background-position: -128px -68px;}
    to {background-position: -192px -68px;}
  }
  
  @keyframes knight-walk {
    from {background-position: -192px -68px;}
    to {background-position: -256px -68px;}
  }
  
  
  /* wizard animation */
  
  .wizard-idle{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -128px -164px;
    width: 16px;
    height: 28px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    animation: wizard-idle 0.4s steps(4) infinite;
  }
  
  /* .wizard-walk{ */
  

  .wizard{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -192px -164px;
    width: 16px;
    height: 28px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    animation: wizard-walk 0.4s steps(4) infinite;
  }


  
  @keyframes wizard-idle {
    from {background-position: -128px -164px;}
    to {background-position: -192px -164px;}
  }
  
  @keyframes wizard-walk {
    from {background-position: -192px -164px;}
    to {background-position: -256px -164px;}
  }
  
  
  /* elf animation */
  
  .elf-idle{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -128px -4px;
    width: 16px;
    height: 28px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    animation: elf-idle 0.4s steps(4) infinite;
  }
  
  /* .elf-walk{ */
    .elf{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -192px -4px;
    width: 16px;
    height: 28px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    animation: elf-walk 0.4s steps(4) infinite;
  }

  
  
  @keyframes elf-idle {
    from {background-position: -128px -4px;}
    to {background-position: -192px -4px;}
  }
  
  @keyframes elf-walk {
    from {background-position: -192px -4px;}
    to {background-position: -256px -4px;}
  }
  
  /* lizard animations */
  
  .lizard-idle{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -128px -196px;
    width: 16px;
    height: 28px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    animation: lizard-idle 0.4s steps(4) infinite;
  }
  
  /* .lizard-walk{ */
    .lizard{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -192px -196px;
    width: 16px;
    height: 28px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 999;
    animation: lizard-walk 0.4s steps(4) infinite;
  }

  
  @keyframes lizard-idle {
    from {background-position: -128px -196px;}
    to {background-position: -192px -196px;}
  }
  
  @keyframes lizard-walk {
    from {background-position: -192px -196px;}
    to {background-position: -256px -196px;}
  }


  .lizard-character-card{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -192px -196px;
    width: 16px;
    height: 28px;
    position: relative;
    z-index: 999;
    margin: 1rem;
    transform: scale(2);
    animation: lizard-walk 0.4s steps(4) infinite;
  }


  .elf-character-card{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -192px -4px;
    width: 16px;
    height: 28px;
    position: relative;
    z-index: 999;
    margin: 1rem;
    transform: scale(2);
    animation: elf-walk 0.4s steps(4) infinite;
  }

  .wizard-character-card{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -192px -164px;
    width: 16px;
    height: 28px;
    position: relative;
    z-index: 999;
    margin: 1rem;
    transform: scale(2);
    animation: wizard-walk 0.4s steps(4) infinite;
  }

  .human-character-card{
    display: inline-block;
    background-image: url("../assets/DungeonTileSet.png");
    background-position: -192px -68px;
    width: 16px;
    height: 28px;
    position: relative;
    z-index: 999;
    margin: 1rem;
    transform: scale(2);
    animation: knight-walk 0.4s steps(4) infinite;
  }

  /* Modifiers */

  .scaled{
    transform: scale(1);
  }

  .--facingleft{
    transform: scale(-1,1);
  }

  .--facingright{
    /* //testing purposes */
    transform: scale(1,1);
  }

  .inplay{
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .nametag{
    z-index: 1000;
    font-size: .5rem;
    width: 1rem;
    color: white;
    top: -1.3rem;
    right: 0rem;
    font-family: pixelated;
    position: absolute;
    background-color: rgba(0,0,0,.3)
  }

  .heartscontainer{
    display: inline-block;
    transform: scale(.25,.25);
    position: absolute;
    width: 400%;
    top: -1.16rem;
    right: -1.5rem;
    z-index: 900;
  }

  .--player{
    position: absolute;
    top: -.78rem;
  }


.--avatar{
  position: relative;
  z-index: 9000;
}

.--skill1-right{
  animation: right-spin 400ms 1;
}

.--skill1-left{
  animation: left-spin 400ms 1;
}

@keyframes left-spin {
  from {
    transform: rotate(0deg)
  } to {
    transform: rotate(-360deg)
  }
}

@keyframes right-spin {
  from {
    transform: rotate(0deg)
  } to {
    transform: rotate(360deg)
  }
}