.ui_heart_full{
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position: -288px -256px;
    position: relative;
}
.ui_heart_half{
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position: -304px -256px;
    position: relative;
}
.ui_heart_empty{
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position: -320px -256px;
    position: relative;
}

.weapon_knife{
    display: inline-block;
    position: absolute;
    width:  6px;
    height: 13px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position:  -293px -18px;
}
.weapon_rusty_sword{
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 21px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position:  -307px -26px;
}
.weapon_regular_sword{
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 21px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position:  -323px -26px;
}
.weapon_red_gem_sword{
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 21px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position:  -339px -26px;
}
.weapon_big_hammer{
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 37px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position:  -291px -42px;
}
.weapon_hammer{
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 24px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position:  -307px -55px;
}
.weapon_baton_with_spikes{
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 22px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position:  -323px -57px;
}
.weapon_mace{
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 24px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position:  -339px -55px;
}
.weapon_katana{
    display: inline-block;
    position: absolute;
    width:  6px;
    height: 29px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position:  -293px -82px;
}
.weapon_saw_sword{
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 25px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position:  -307px -86px;
}
.weapon_anime_sword{
    display: inline-block;
    position: absolute;
    width: 12px;
    height: 30px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position:  -322px -81px;
}
.weapon_axe{
    display: inline-block;
    position: absolute;
    width:  9px;
    height: 21px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position: -341px -90px;
}
.weapon_machete {
    display: inline-block;
    position: absolute;
    width:  5px;
    height: 22px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position: -294px -121px;
}
.weapon_cleaver {
    display: inline-block;
    position: absolute;
    width:  8px;
    height: 19px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position: -310px -124px;
}
.weapon_duel_sword {
    display: inline-block;
    position: absolute;
    width:  9px;
    height: 30px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position: -325px -113px;
}
.weapon_knight_sword {
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 29px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position: -339px -114px;
}
.weapon_golden_sword {
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 22px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position: -291px -153px;
}
.weapon_lavish_sword {
    display: inline-block;
    position: absolute;
    width: 10px;
    height: 30px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position: -307px -145px;
}
.weapon_red_magic_staff {
    display: inline-block;
    position: absolute;
    width:  8px;
    height: 30px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position: -324px -145px;
}
.weapon_green_magic_staff {
    display: inline-block;
    position: absolute;
    width:  8px;
    height: 30px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position: -340px -145px;
}
.weapon_spear {
    display: inline-block;
    position: absolute;
    width:  6px;
    height: 30px;
    background-image: url('../assets/DungeonTileSet.png');
    background-position: -293px -177px;
}

.right{
    transform: rotate(15deg);
    right: -.1rem;
    top: .1rem;
    z-index: 800;
}

.right.stab{
    transform: rotate(90deg);
    right: -.3rem
}

.left.stab{
    transform: rotate(-90deg) scale(-1,1);
    right: .9rem;
}

.left{
    transform: rotate(-15deg) scale(-1,1);
    right: .7rem; 
    top: .1rem;
    z-index: 800;
}


/* monster weapon classes / animation. */

.left.monster_ver{
    transform: rotate(-40deg) scale(-1,1) scale(.7,.7); ;
    right: .6rem; 
    top: -.3rem;
    z-index: 600;
}

.right.monster_ver{
    transform: rotate(40deg) scale(-1,1) scale(.7,.7);
    right: -.3rem; 
    top: -.3rem;
    z-index: 600;
}

.right.stab.monster_ver{
    transform: rotate(90deg) scale(.7,.7);
    right: -.3rem
}

.left.stab.monster_ver{
    transform: rotate(-90deg) scale(-1,1) scale(.7,.7);
    right: .9rem;
}

.skill.left{
    /* display: inline-block; */
    position: absolute;
    top: -.5rem;
    left: -.6rem;
    z-index: 800;
    transform: scale(.8,-.8) rotate(-90deg);
}

.skill.right{
    /* display: inline-block; */
    position: absolute;
    top: -.5rem;
    left: .9rem;
    z-index: 800;
    transform: scale(.8,.8) rotate(90deg)
}

.hidden{
    display: none;
}