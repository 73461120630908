.App {
  text-align: center;
  position: relative;
  width: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#navbar{
  /* border: 2px dotted red; */
  position: relative;
  /* width: 90%; */
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 1rem; */
  padding: 1rem;
  font-family: brushed;
  font-size: 4rem;
  color: lightgray;
  text-align: center;
}

.loginContainer{
  display: inline-block;
  position: relative;
  width: 90%;
}

.carouselcontainer{
  position: relative;
  display: inline-block;
  padding: 2rem;
}
.carousel.carousel-slider{
    position: relative;
    display: inline-block;
    width: 40%;
}

#loginForm{
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, .5);
  box-shadow: 5px 5px rgba(0, 0, 0, .8);
  color: lightgray;
  font-family: pixelated;
  font-size: 1.5rem;
  background-color: rgba(0, 0, 0, .1);
  position: relative;
  padding: 3rem;
  text-align: center;
}

#loginInfo{
  /* border: 2px dotted red; */
  position: relative;
  /* width: 90%; */
  margin-left: auto;
  margin-right: auto;
  /* margin-bottom: 1rem; */
  padding: 1rem;
  font-family: pixelated;
  font-size: 1.5rem;
  color: lightgray;
  text-align: center;
}

.form-control{
  margin: 1rem;
}

#linkcontainer{
  position: absolute;
  top: -2rem;
  right: .5rem;
}

.buttonlink{
  margin: 5px;
  font-size: 1.2rem;
  color: white;
  background: none!important;
  border: none;
  padding: 0!important;
  font-family: pixelated;
  cursor: pointer;
}

#credits{
  font-family: pixelated;
  color: lightgray;
  text-align: left;
  font-size: 1.5rem;
  width: 70%;
  position: relative;
  margin: 0 auto;
}

#pagenotfound{
  padding: 1rem;
  position: relative;
  font-family: pixelated;
  color: lightgray;
  font-size: 5rem;
  margin: 5% auto 0;
  width: 50%;
  transform: scale(1,1);
}

.notfoundtext{
  color: darkred;
  font-size: 2rem;
}

@font-face {
  font-family: brushed;
  src: url(./assets/SlackerBrush.ttf);
}
